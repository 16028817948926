import{ createTheme } from '@mui/material/styles';

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette['primary'];
  }
  interface PaletteOptions {
    white: PaletteOptions['primary'];
  }
}

export const Theme = createTheme({
  palette: {
    background: {
      default: "#EEEEEE",
    },
    primary: {
      main: "#0E5120",
      dark: "#093816",
      light:"#DADADA"
    },
    secondary: {
      main: '#DADADA',
      dark: "#B26F00",
      light:"#DADADA"
    },
    warning: {
      main: '#ed6c02',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#f91f0f',
    },
    info: {
      main: '#0288d1'
    },
    success: {
      main: '#2e7d32'
    },
    white: {
      main:'#FFFFFF'
    }
  },
});

export const DarkTheme = createTheme({
  palette: {
    mode: 'dark',
    text: {
      primary: 'rgba(255, 255, 255, 0.8)',
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)"
    },
    background: {
      default: "#141414",
      paper: "#1F1F1F",
    },
    primary: {
      main: "#1E9D41"
    },
    secondary: {
      main: '#ff9f00',
    },
    warning: {
      main: '#FF9F00',
      dark: "#FF9F00",
      light: "#FF9F00",
      contrastText: '#000000'
    },
    error: {
      main: '#f91f0f',
    },
    info: {
      main: '#0288d1'
    },
    success: {
      main: '#2e7d32'
    },
    white: {
      main:'#000000'
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#1F1F1F',
          "&.Mui-selected": {
            backgroundColor: '#404040'
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#1F1F1F',
          "&.Mui-selected": {
            backgroundColor: '#404040'
          }
        }
      }
    }
  }
});