import { useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderProps } from '../../infra/interfaces/layouts/header';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { AccountCircle, SupervisorAccount } from '@mui/icons-material';
import logo from '../../assets/images/logo-primary.png';
import { Autocomplete, Badge, Button, ButtonGroup, FormControl, Grid, Menu, MenuItem, TextField, Tooltip } from '@mui/material';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LogoutIcon from '@mui/icons-material/Logout';
import ContactPageIcon from '@mui/icons-material/ContactPage';

import '../../styles/CSSs/header.css'

import PersonIcon from '@mui/icons-material/Person';
import useFilter from '../../utility/hooks/useFilter';
import useShoppingCart from '../../utility/hooks/useShoppingCart';
import { parseDecimalFormat } from '../../utility/functions/numbers';
import { clearAllCookies, getTokenPayload, getUserPermissionCodeByRole } from '../../utility/functions/storage';
import Model from './model';

import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import useThemeColorMode from '../../utility/hooks/useThemeColorMode';


export default function Header(props:HeaderProps){
  
  const themeMode = useThemeColorMode()

  const [navigationButtons] = React.useState<any[]>(props.dataMenu)

  const [settings, setSettings] = React.useState<string[]>(['Perfil', 'Admin','Pedidos', 'Contato','Logout'])
  const [mobileSettings, setMobileSettings] = React.useState<string[]>(['Perfil', 'Admin', 'Carrinho', 'Pedidos', 'Contato', 'Logout'])

  const [ userInfo, setUserInfo ] = React.useState<any>("")

  const [autoCompleteValue, setAutoCompleteValue] = React.useState<any>("")

  const [ total, setTotal ] = React.useState<string|number>(0.00)
  
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const { handleFilterByName, handleFilterById, handleFilterByType } = useFilter();
  const { badgeValue, totalValue, handleTotalValue, addtionBadgeValue} = useShoppingCart();

  const [ hiddenHeader, setHiddenHeader ] = React.useState<boolean>(false);

  let codSup = getUserPermissionCodeByRole('Supervisor');
  let codVen = getUserPermissionCodeByRole('CodVen');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isPc = useMediaQuery(theme.breakpoints.up('md'));

  const openCategoryMenu = Boolean(anchorElNav);
  const openUserMenu = Boolean(anchorElUser);

const getCartQtd = React.useCallback(async() => {

  const { data } = await Model.getShoppingCart();
 
  addtionBadgeValue(data.Quantidade);
  handleTotalValue(data.Total);
},[badgeValue, totalValue])

const onChangeAutoComplete = (target:any) => {
    setAutoCompleteValue(target??"")
    handleFilterByName(target??"")
};

const onClickButtonFilter = (value:string|number) => {
  handleFilterById(value)
}

const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  setAnchorElUser(event.currentTarget);
};

const goToMain = () => {
  navigate('/main')
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
};

const goToCar = () => {
  navigate('ecommerce/checkout');
};

const settingsOptionsToGo = (target:any|string) => {
  switch(target){
    case 'Perfil':
      window.location.href = '/ecommerce/user';
      break;
    case 'Admin':
      window.location.href = '/admin/panel';
      break;
    case 'Contato':
      window.location.href = '/ecommerce/faq';
      break;
    case 'Pedidos':
      window.location.href = '/ecommerce/orders';
      break;
    case 'Carrinho':
      window.location.href = '/ecommerce/checkout';
      break;
    case 'Logout':
      clearAllCookies();
      break;
    default:
      break;
  }
};

const handleCloseUserMenu = () => {
  setAnchorElUser(null);
};

const onClickInNavButton = (event: React.MouseEvent<HTMLElement>):void => {
    setAnchorElNav(event.currentTarget);
};

const handleCloseCategoryMenu = () => {
  setAnchorElNav(null);
};

const getUserData = () => {
  setUserInfo(getTokenPayload())
}

React.useEffect(() => {
  setTotal(totalValue)
},[badgeValue, totalValue])

React.useEffect(() => {
  getCartQtd()
},[total])

React.useEffect(() => {
  if (typeof document !== "undefined") {
    document.addEventListener("mousewheel", (e: any) => {
      if (e) {
        if (e.deltaY === -100) {
          setHiddenHeader(false)
        }
        if (e.deltaY === 100) {
          setHiddenHeader(true)
        }
      }
    });
  }
},[hiddenHeader])

React.useEffect(() => {
  if( codSup || codVen ) {
    setSettings(['Perfil', 'Admin', 'Carrinho', 'Pedidos', 'Contato','Logout'])
    setMobileSettings(['Perfil', 'Admin', 'Carrinho', 'Pedidos', 'Contato','Logout'])
  } else {
    setSettings(['Perfil', 'Carrinho', 'Pedidos', 'Contato','Logout'])
    setMobileSettings(['Perfil', 'Carrinho', 'Pedidos', 'Contato','Logout'])
  }
},[])

React.useEffect(() => {
  getUserData()
},[])

  return(
    <React.Fragment>
    <Grid 
      container 
      id='HeaderFather'
    >
      <Box sx={{ flexGrow: 1 }}>
        <AppBar color="secondary" sx={{
          top: 0,
          left: 0,
        }}>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={11} xl={9}>
              {
                isPc?
                  <Grid 
                    container 
                    spacing={0}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={2} display='flex' justifyContent='center' alignItems='center' className="appbarTop">
                      <img alt='toolbar_logo' src={logo} onClick={goToMain} className="IMGPointer" id="logoIMG"/>
                    </Grid>
                    <Grid item xs={6}
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        className="appbarTop"
                      >
                        <FormControl 
                        fullWidth
                        size="small"
                        sx={{
                          borderRadius:'0px'
                        }}
                        // variant="outlined"
                        >
                          <TextField 
                            fullWidth
                            className="AutoComplete"
                            size='small'
                            value={autoCompleteValue}
                            sx={{
                              my:'1rem',
                              backgroundColor: themeMode.themeColorMode === 'dark'? '' : '#FFF'
                            }}
                            onChange={({target}) => onChangeAutoComplete(target.value)}
                            placeholder="Pesquisar..."
                          />
                        </FormControl>
                        <Button
                          onClick={(event:any) => onChangeAutoComplete(autoCompleteValue)}
                          variant="contained"
                          size="medium"
                          className='AutoCompleteButton'
                          sx={{
                            mx:'5px',
                            borderRadius:'50px',
                            width:'10%'
                          }}
                        >
                          <SearchIcon 
                            sx={{
                              color:'#FFF'
                            }}
                          />
                        </Button>
                    </Grid>
                    <Grid item xs={1} display={'flex'} justifyContent={'center'}>
                     <IconButton onClick={themeMode.toggleColorMode} sx={{color:themeMode.themeColorMode === "dark" ?"#FFF" : "#000"}} edge="end">
                      { themeMode.themeColorMode === "dark" ? <Brightness7Icon /> : <Brightness4Icon /> }
                     </IconButton>
                    </Grid>
                    <Grid item xs={3} display='flex' alignItems='center' justifyContent='center' sx={{paddingRight:'15px'}} className="appbarTop">
                   
                    <Menu
                      // sx={{ mt: '45px', display:'flex'}}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={openUserMenu}
                      onClose={handleCloseUserMenu}
                      onClick={handleCloseUserMenu}
                    >
                      {settings.map((setting, index) => (
                        <MenuItem key={index} onClick={() => {
                          settingsOptionsToGo(setting)
                        }}>
                          {
                            setting === 'Perfil' ?
                              <PersonIcon />
                            :
                            setting === 'Admin' ?
                              <SupervisorAccount />
                            :
                            setting === 'Carrinho' ?
                              <ShoppingCartIcon />
                            :
                            setting === 'Pedidos' ?
                              <LocalMallIcon />
                            :
                            setting === 'Contato' ?
                              <ContactPageIcon />
                            :
                            setting === 'Logout' ?
                              <LogoutIcon />
                            :
                            <></>
                          }
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                    </Menu> 
                    </Grid>
                    <Grid item xs={12}
                      className="FiltersLine"
                      sx={{
                        height: hiddenHeader ? "0px": "45px"
                      }}
                    >
                      <ButtonGroup 
                        variant="text" 
                        aria-label="text button group"
                        fullWidth
                      >
                        <Button
                          sx={{
                            width:'flex',
                            backgroundColor: themeMode.themeColorMode === 'dark'? '' : '#DADADA',
                            my: '0.4rem',
                            "&:hover":{
                              backgroundColor:'#FFF',
                            }
                          }}
                          size="small"
                          onClick={onClickInNavButton}
                          fullWidth
                        >
                          <Typography 
                            sx={{ 
                                mx:'2rem',
                                fontSize:'11px',
                                fontFamily:'Arial, Helvetica, sans-serif', 
                                fontWeight:'bold',
                                width: 'flex', 
                                color: themeMode.themeColorMode === 'dark'? '' : '#000',
                                
                                }}>
                                  Todos Produtos
                          </Typography>
                        </Button>
                        <Menu
                          id="CategoryMenu"
                          anchorEl={anchorElNav}
                          open={openCategoryMenu}
                          onClose={handleCloseCategoryMenu}
                          onClick={handleCloseCategoryMenu}
                          MenuListProps={{
                            'aria-labelledby': 'CategoryMenu',
                          }}
                          sx={{
                            backgroundcolor:"#FF9F00"
                          }}
                        >
                          {props.itensFilter.map((element, index) =>{
                            return(
                              <MenuItem key={index} onClick={() => handleFilterByType(element.codgrupo)} sx={{ borderBottom:"0.5px solid gray" }}>
                                {element.descricao}
                              </MenuItem>
                            )
                          },[])}
                        </Menu>
                        {navigationButtons.map((bt) =>{
                          return(
                            <Button
                              key={bt.id}
                              sx={{
                                backgroundColor: themeMode.themeColorMode === 'dark'? '' : '#DADADA',
                                my: '0.4rem',
                                "&:hover":{
                                  backgroundColor: themeMode.themeColorMode === 'dark'? '' : '#FFF'
                                }
                              }}
                              fullWidth
                              onClick={() => onClickButtonFilter(bt.id)}
                            >
                              <Typography sx={{ 
                                mx:'2rem', 
                                fontSize:'11px', 
                                fontFamily:'Arial, Helvetica, sans-serif', 
                                fontWeight:'bold',
                                width: 'flex', 
                                color: themeMode.themeColorMode === 'dark'? '' : '#000',
                                
                                }}>
                                {bt.label}
                              </Typography>
                              {
                                bt.icon? bt.icon : <></>
                              }
                            </Button>
                          )
                        })}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                : 
                isMobile?
                  <Grid 
                    container 
                    spacing={0}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      my:'0.5rem'
                    }}
                  >
                    <Grid item xs={2} display="flex" justifyContent={'center'}>
                      <IconButton 
                        onClick={props.toggleDrawer}
                      >
                        <MenuIcon fontSize='large' />
                      </IconButton>
                    </Grid>
                    <Grid item xs={7} display="flex" justifyContent={'center'}>
                      <img alt='toolbar_logo' src={logo} onClick={goToMain} className="IMGPointer" id="logoIMG"/>
                    </Grid>
                    <Grid item xs={1} display={'flex'} justifyContent={'center'}>
                     <IconButton onClick={themeMode.toggleColorMode} sx={{color:themeMode.themeColorMode === "dark" ?"#FFF" : "#000"}} edge="end">
                      { themeMode.themeColorMode === "dark" ? <Brightness7Icon /> : <Brightness4Icon /> }
                     </IconButton>
                    </Grid>
                    <Grid item xs={2} display="flex" justifyContent={'center'}>
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} >
                        <AccountCircle  sx={{ width:'50px', height:'50px', color:themeMode.themeColorMode === "dark" ? '#FFF':'#373f47' }}/>
                      </IconButton>
                      <Menu
                        // sx={{ mt: '45px', display:'flex'}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                          }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {mobileSettings.map((mobileSettings) => (
                          <MenuItem key={mobileSettings} onClick={() => {
                            settingsOptionsToGo(mobileSettings)
                          }}>
                            {
                            mobileSettings === 'Perfil' ?
                              <PersonIcon />
                            :
                            mobileSettings === 'Admin' ?
                              <SupervisorAccount />
                            :
                            mobileSettings == 'Carrinho' ?
                              <ShoppingCartIcon />
                            :
                            mobileSettings === 'Pedidos' ?
                              <LocalMallIcon />
                            :
                            mobileSettings === 'Contato' ?
                              <ContactPageIcon />
                            :
                            mobileSettings === 'Logout' ?
                              <LogoutIcon />
                            :
                            <></>
                          }
                            <Typography textAlign="center">{mobileSettings}</Typography>
                          </MenuItem>
                        ))}
                      </Menu> 
                    </Grid>
                    <Grid item xs={11.5}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      className="appbarTop"
                    >
                      <FormControl 
                      fullWidth
                      size="small"
                      sx={{
                        borderRadius:'0px'
                      }}
                      // variant="outlined"
                      >
                        <TextField 
                            fullWidth
                            className="AutoComplete"
                            size='small'
                            value={autoCompleteValue}
                            sx={{
                              my:'1rem'
                            }}
                            onChange={({target}) => onChangeAutoComplete(target.value)} 
                            placeholder="Pesquisar..."
                          />
                      </FormControl>
                    </Grid>
                    <Grid item xs={11.5}
                      className="MobileFiltersLine"
                      sx={{
                        height: hiddenHeader ? "0px": "40px"
                      }}
                    >
                      <Button 
                        variant={'outlined'}
                        fullWidth
                        onClick={props.toggleDrawer}
                      >
                        <Typography 
                          fontWeight={'bold'}                            
                        >
                          Filtros
                        </Typography>
                      </Button>
                    </Grid> 
                  </Grid>
                :
                  <></>
                }
            </Grid>
          </Grid>
        </AppBar>
      </Box>
    </Grid>
  </React.Fragment>
  )
}