import { Box, Typography, Link, Grid } from "@mui/material"
import '../../styles/CSSs/footer.css'
import useThemeColorMode from "../../utility/hooks/useThemeColorMode"

export default function Footer() {

  const themeMode = useThemeColorMode()

  return <>
  <Box component="footer" p={'1.1rem'} height="60px" sx={{
    backgroundColor: themeMode.themeColorMode === "dark" ? '#141414' : '#EEEEEE',
    mt:'5rem',
    bottom:'0',
    left:'0',
    width:'100%'
  }}>
    <Box >
      <Grid item display="flex" justifyContent={"space-between"}>
        <Typography className="WarningStyle">
          COPYRIGHT © {new Date().getFullYear()} <Link href="https://cifaldistribuidora.com.br" underline="none" >Cifal Distribuidora</Link>, Todos os direitos reservados
        </Typography>
        <Typography className="WarningStyle">
          Cifal P&D | v2.0
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className="WarningStyle">
        ATENÇÃO “É expressamente proibida a cópia, reprodução e divulgação dos textos, fotos, ilustrações, entre outros, contidos nesta página. Trata-se de um material de uso exclusivo entre empresas (B2B), não podendo ser compartilhado com o público em geral, haja vista não ser destinado à publicidade e propaganda, conforme a lei.
        </Typography>
      </Grid>
    </Box>
  </Box>
  </>
}