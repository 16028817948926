import { Fragment, lazy, useCallback, useEffect, useState } from 'react';
import { Outlet, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Feedback from '../components/Feedback';
import Layout from '../components/layouts';
import FeedbackProvider from '../utility/context/Feedback';
import { getUserPermissions, parseJwt } from '../utility/functions/storage';
import { hasCommonElement } from '../utility/functions/validators';


const Login = lazy(()=> import('../pages/login'))
const Main = lazy(()=> import('../pages/main'))
const EcommerceUser = lazy(()=> import('../pages/user'))
const EcommerceOrders = lazy(()=> import('../pages/orders'))
const EcommerceFaq = lazy(()=> import('../pages/faq'))
const EcommerceProducts = lazy(()=> import('../pages/products'))
const EcommerceCheckout = lazy(()=> import('../pages/checkout'))
const ECampaign = lazy(()=> import('../pages/ecampaign'))

const AdminPanel = lazy(()=> import('../pages/admin/panel'))
const AdminHideBySubGroup = lazy(()=> import('../pages/admin/hidebysubgroup'))
const AdminOrders = lazy(()=> import('../pages/admin/orders'))
const AdminProdutos = lazy(()=> import('../pages/admin/produtos'))
const AdminUsers = lazy(()=> import('../pages/admin/users'))
const AdminKits = lazy(()=> import('../pages/admin/kits'))
const AdminCampaign = lazy(()=> import('../pages/admin/campaign'))

interface RouterProps {
  token: string
}

export default function Router({token}:RouterProps){
  const location = useLocation()
  const [ userPermissions, setUserPermissions ] = useState<string[]>(getUserPermissions())

  const getDataFromTokenPayload = useCallback((payload:any) => {
    const permissions = payload ? payload.role : null
    if(permissions) {
      setUserPermissions(permissions)
    }
  }, [])

  useEffect(() => {
    const payload = parseJwt(token)
    if(payload) getDataFromTokenPayload(payload)
  },[token, getDataFromTokenPayload])

  return <FeedbackProvider>
    <Routes>
      <Route path="/" element={<Login/>} />
      <Route element={ <Layout currentPath={location.pathname}><Outlet/></Layout> }>
        <Route path='/main' element={<Main/>}/>
        <Route path='/ecommerce/user' element={<EcommerceUser/>}/>
        <Route path='/ecommerce/orders' element={<EcommerceOrders/>}/>
        <Route path='/ecommerce/faq' element={<EcommerceFaq/>}/>
        <Route path='/ecommerce/products' element={<EcommerceProducts/>}/>
        <Route path='/ecommerce/checkout' element={<EcommerceCheckout/>}/>
        <Route path='/ecommerce/ecampaign' element={<ECampaign/>}/>
        {
          userPermissions.includes( "CodVen" ) || userPermissions.includes( "Supervisor" ) || userPermissions.includes( "<$developer%>" ) ?
            <Fragment>
              <Route path='/admin/panel' element={<AdminPanel/>}/>
              <Route path='/admin/hidebysubgroup' element={<AdminHideBySubGroup/>}/>
              <Route path='/admin/orders' element={<AdminOrders/>}/>
              <Route path='/admin/produtos' element={<AdminProdutos/>}/>
              <Route path='/admin/users' element={<AdminUsers/>}/>
              <Route path='/admin/kits' element={<AdminKits/>}/>
              <Route path='/admin/campaign' element={<AdminCampaign/>}/>
            </Fragment>  
          :
          <Fragment></Fragment>  

        }
      </Route>
    </Routes>
    <Feedback />
  </FeedbackProvider>

}