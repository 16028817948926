/**
  * Set a new cookie.
  * 
  * @param {string} name 
  * @param {string} value 
  * @param {number} days How long this cookie will live.
*/
export function setCookie(name:string, value:string, days:number) {
  let expires:string = "";
  
  if (days > 0) {
    let date:Date = new Date();

    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    date.setHours(0, 0, 0, 0);
    expires = "; expires=" + date.toUTCString();
  }
  
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

/**
  * Get a cookie.
  * 
  * @param {string} name 
*/
export function getCookie(name:string) {
  try {
    let result:string|null = null;
    if(typeof window !== "undefined"){
      let nameEQ = name + "=",
      cookieList = document ? document.cookie.split(';'): ''

      for(let i=0;i < cookieList.length;i++) {
        let cookie = cookieList[i];
        while (cookie.charAt(0)==' ') 
          cookie = cookie.substring(1,cookie.length);
        if (cookie.indexOf(nameEQ) == 0) {
          result = cookie.substring(nameEQ.length, cookie.length);
          break;
        }
      }
    }
    return result;

  } catch(error){
    console.log(error);
    return null;
  }
}

/**
  * Delete a cookie.
  * 
  * @param {string} name 
*/
export function eraseCookie(name:string) {   
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT;';
}

/**
  * Delete all cookies.
*/
export function clearAllCookies(){
  document.cookie.split(";").forEach(function(c) { 
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); 
  });
  window.location.href="/"
}

/**
  * Parse a JSON Web Token and extract the payload. It doesn't verify the signature.
  * @param {string} token
*/
export function parseJwt(token:any) {
  try {
    if(!token) return null
    let base64Url:string = token.split('.')[1];
    let base64:string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch(error){
    return null
  }
}; 

/**
  * Get token payload from cookies.
  * @returns {Object|null} payload Object
*/
export function getTokenPayload() {
  try {
    let token = getCookie('token')
    if(token) 
      return parseJwt(token)
    else
      return null
  } catch (error){
    return null
  }
}

export function getUserPermissions() {
  try {
    const payload = getTokenPayload()
    if(payload)
      return payload.role
    else
      return []
  } catch(error){
    console.log(error)
    return []
  }
}

/**
 * Search the permissions field in the token and return if found
 * @param role 
 * @returns 
 */

export function getUserPermissionByRole(role:string|null): string|null|undefined{
  let perm:string|null = null
  try {
    let permissions = getUserPermissions().split(";")
    permissions.forEach((element:any) => {
      if(element.indexOf(role) !== -1){
        if(role === "Diretor" || role === '<$developer%>'){
          perm = element
        } 
        perm = element.split('|')[0]
        
    } else {
      return 
    }
  
  })
    return perm
  } catch(error){
    return null
  }
}

/**
 * Search the permissions field in the token and return the code if found
 * @param role 
 * @returns 
 */

export function getUserPermissionCodeByRole(role:string|null|number){
  let perm:string|null|number = null
  try {
    let permissions = getUserPermissions().split(";")
    permissions.forEach((element:any) => {
    if(element.indexOf(role) !== -1){
      perm = element.split('|')[1]
    } else {
      return 
    }
  })
    return perm
  } catch(error){
    return 0
  }
}

/**
 * Set cookie if over 18 years old
 */
export function acceptOver18YearsOld(){
  setCookie('howOld', 'true', 7)
}

/**
 * Returns if cookies is active
 * @returns 
 */
export function verifyHowOld(){
  if( !getCookie('howOld') ){
    return true
  }else{
    return false
  }
}