import { createContext, useMemo, useState } from "react";

export interface CartContextInterface {
  badgeValue: number;
  totalValue: number;
  addtionBadgeValue: ( value: number ) => void;
  reduceBadgeValue: ( value: number ) => void;
  handleTotalValue: ( value: number ) => void;
};

export const ShoppingCart = createContext<CartContextInterface>({
  badgeValue: 0,
  totalValue: 0,
  addtionBadgeValue: ( value: number ) => {},
  reduceBadgeValue: ( value: number ) => {},
  handleTotalValue: ( value: number ) => {}
});

export const ShoppingCartProvider = ({children}:any) => {
  const [ badgeValue, setBadgeValue ] = useState<number>(0);
  const [ totalValue, setTotalValue ] = useState<number>(0);

  const addtionBadgeValue = (value: number) => {
    setBadgeValue(value)
  };

  const reduceBadgeValue = (value: number) => {
    let item = badgeValue
    if(badgeValue > 0){
      item -= value
    }
    setBadgeValue(item)
  };

  const handleTotalValue = (value: number) => {
    setTotalValue(value)
  };

  const value = useMemo(() => ({
    badgeValue,
    totalValue,
    addtionBadgeValue,
    reduceBadgeValue,
    handleTotalValue
  }),[badgeValue, totalValue, addtionBadgeValue, reduceBadgeValue, handleTotalValue])

  return(
    <ShoppingCart.Provider value={value}>
      { children }
    </ShoppingCart.Provider>
  );
};

