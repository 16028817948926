import {
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress
} from '@mui/material'
import useFeedback from '../../utility/hooks/useFeedback';
// import useFeedback from 'utility/hooks/useFeedback'

export default function Feedback(){
  const { alert, hideAlert, loader } = useFeedback();

  return <>
    <Snackbar open={alert.open} onClose={hideAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={hideAlert} severity={alert.severity} variant="filled" sx={{ width: '100%' }}>
        {alert.message}
      </Alert>
    </Snackbar>
    <Backdrop sx={{ color: '#fff', zIndex: 1399 }} open={loader}>
      <CircularProgress color="inherit" />
    </Backdrop>
  </>
}