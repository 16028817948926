import { ArrowBack, ArrowForwardIos, Home } from "@mui/icons-material"
import { Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography } from "@mui/material"
import useFilter from "../../utility/hooks/useFilter"
import "../../styles/CSSs/sideMenu.css"
import useThemeColorMode from "../../utility/hooks/useThemeColorMode"

interface SideMenuProps{
  openMenu: boolean
  toggleOpenMenu: () => void
  userName: string
  dataMenu: any[]
  subgroupItens: any[]
  path?: string
}

export default function SideMenu(props: SideMenuProps){

  const themeMode = useThemeColorMode()
  const { handleFilterById, handleFilterByType } = useFilter()
  
  const onClickButtonFilter = (value:string|number) => {
    props.toggleOpenMenu()
    handleFilterById(value)
  }

  return(
    <Drawer 
      variant="temporary" 
      open={props.openMenu} 
      anchor="left"
      ModalProps={{ onBackdropClick: props.toggleOpenMenu }} 
      PaperProps={{
        sx: { 
          width: {xs:'70vw',  sm:'400px'}, 
          maxWidth:'70vw',
        },
      }}
    >
      <List>
        <ListItem>
          <IconButton 
            onClick={props.toggleOpenMenu} 
            size="small"
            sx={{
              fontWeight:"bold"
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography
            sx={{
              ml:'0.5rem'
            }}
            color="primary"
            fontWeight={'bold'}
          >
            Olá, {props.userName}!
          </Typography>
        </ListItem>
        <ListItemButton key="home" component="a" href="/main" selected={'/main' === props.path} sx={{
          backgroundColor: themeMode.themeColorMode === 'dark'? '' : '#DADADA',
          borderBottom:"0.5px solid gray",
          borderTop:"0.5px solid gray"
        }}>
          <ListItemIcon>{<Home />}</ListItemIcon>
          <ListItemText>Home</ListItemText>
        </ListItemButton>
        {props.dataMenu.map((itens:any) => {
          return(
            <ListItemButton key={itens.id}  
              onClick={(params:any) => onClickButtonFilter(itens.id)}
              sx={{
                backgroundColor: themeMode.themeColorMode === 'dark'? '' : '#DADADA',
                borderBottom:"0.5px solid gray"
            }}>
              <ListItemIcon>
                {itens.icon}
              </ListItemIcon>
              <ListItemText>
                {itens.label}
              </ListItemText>
            </ListItemButton>
          )
        })
        }
      <Divider />
      </List>
      <List>
        <ListSubheader color="primary" sx={{ textAlign:'center', mb:'1rem', mt:'0.5rem' }}>
          <Typography fontWeight={'bold'}>
            Filtrar Produtos
          </Typography>
        </ListSubheader>
        {props.subgroupItens.map((itens:any, index:number) =>{
          return(
            <ListItemButton key={index} sx={{ borderTop:"0.5px solid gray", backgroundColor: themeMode.themeColorMode === 'dark'? '' : '#DADADA' }}>
              <ListItemText onClick={() => handleFilterByType(itens.codgrupo)}>
                {itens.descricao}
              </ListItemText>
            </ListItemButton>
          )
        })}
      </List>
    </Drawer>
  )
}