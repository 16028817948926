import { Box, Zoom } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';

interface ScrollTopProps {
  // window?: () => Window
  children: React.ReactElement
}

export default function ScrollTop(props:ScrollTopProps){
  const { 
      children, 
      // window 
    } = props;

  const trigger = useScrollTrigger({
    // target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  )
}