import React, { Suspense, useCallback, useMemo } from 'react';
import logo from './logo.svg';
import { ReactDOM } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './configs/Router'; 

import './App.css';
import ColorModeContext from './utility/context/colorMode';
import useLocalStorage from './utility/hooks/useLocalStorage';
import { useCookie } from './utility/hooks';

import { Theme, DarkTheme } from './configs/themeConfig';
import { ThemeProvider } from '@mui/material/styles';
import { Backdrop, CircularProgress, CssBaseline } from '@mui/material';
import { ProductsFilter, ProductsFilterProvider } from './utility/context/productsFilter';
import { ShoppingCartProvider } from './utility/context/shoppingCart';

export default function App() {
  
  const [ colorMode, setColorMode ] = useLocalStorage('themeMode', 'light')
  const [ token ] = useCookie("token", "")
  
  const toggleColorMode = useCallback(() => {
    if(colorMode === "light"){
      setColorMode("dark")
    } else {
      setColorMode("light")
    }
  }, [colorMode, setColorMode])

  const colorContextValue = useMemo(() => ({
    mode: colorMode,
    toggleColorMode
  }), [colorMode, toggleColorMode])


  return (
    <BrowserRouter>
      <ColorModeContext.Provider value={colorContextValue}>
        <ProductsFilterProvider>
          <ShoppingCartProvider>
            <ThemeProvider theme={colorMode === "light" ? Theme: DarkTheme}>
              <CssBaseline/>
              <Suspense
                fallback={
                  <Backdrop sx={{ backgroundColor: (colorMode === "light" ? '#FFF' : "#141414"), zIndex: (theme:any) => theme.zIndex.drawer + 1 }} open>
                    <CircularProgress color="primary" />
                  </Backdrop> 
                }
              >
                <Router token={token}/>
              </Suspense>
            </ThemeProvider>
          </ShoppingCartProvider>
        </ProductsFilterProvider>
      </ColorModeContext.Provider>
    </BrowserRouter>
  );
}

