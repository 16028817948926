import { Box, useMediaQuery, useTheme, Fab } from '@mui/material'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { clearAllCookies, parseJwt } from '../../utility/functions/storage'
import { hasCommonElement } from '../../utility/functions/validators'
import { useCookie } from '../../utility/hooks'
import useToggle from '../../utility/hooks/useToggle'
import Footer from './footer'
import Header from './header'
import Model from './model'
import SideMenu from './sideMenu'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StarsIcon from '@mui/icons-material/Stars';
import ScrollTop from './scrollTop'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'

const buttons = [
  {
    id: 3,
    label: "Limpar Filtros",
    icon: <HighlightOffIcon fontSize='small'/>
  }
]

interface LayoutProps {
  children?: React.ReactNode
  currentPath?: string
}

export default function Layout(props:LayoutProps){
  const [isDev, setIsDev] = useState<boolean>(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [drawerOpened, toggleDrawer] = useToggle(false)
  const [userPermissions, setUserPermissions] = useState<string[]>([])
  const [username, setUsername] = useState<string>('')
  const [ token ] = useCookie('token', '')
  const [dataMenu, setDataMenu] = useState<any[]>(buttons)
  const [subGroupItens, setSubGroupItens] = useState<any[]>([])

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isPc = useMediaQuery(theme.breakpoints.up('md'))

  const handleLogout = useCallback(() => {
    clearAllCookies()
    window.location.href="/"
  },[])

  const getSubgroupItens = useCallback(async() => {
    const { data, status } = await Model.getSubgroupItens()
    if(status !== 200){
      alert("Ocorreu um erro ao carregar a página, tente novamente mais tarde!")
    } else {
      setSubGroupItens(data)
    }
  },[])

  const getDataFromTokenPayload = useCallback((payload:any) => {
    setUsername(payload.unique_name)
    const permissions = payload ? payload.role : null
    if(permissions) {
      setUserPermissions(permissions)

      if(process.env.REACT_APP_DEV_BUILD === 'true'){
        setIsDev(true)
      }

      if(hasCommonElement(["<$developer%>", "diretor"], permissions)){
        setIsAdmin(true)
      } 
    }
  }, [])

  const verifyToken = useCallback(async () => {
    if(token){
      const { status } = await Model.verifyToken(token)
      if(status === 200) return
    }
    Model.handleUnauthorized()
  }, [token])

  const handleFilterProducts = () => {
    
  }

  useLayoutEffect(() => {
    verifyToken()
  }, [props.currentPath, verifyToken])

  useEffect(() => {
    const payload = parseJwt(token)
    if(payload) getDataFromTokenPayload(payload)
  }, [token, getDataFromTokenPayload, getSubgroupItens])

  useEffect(() => {
    getSubgroupItens()
  },[getSubgroupItens])

  return <>
    <Box sx={{backgroundColor:'#EEEEEE'}}>
      <Header 
        username={username}
        toggleDrawer={toggleDrawer}
        onLogout={handleLogout}
        devBuild={isDev}
        dataMenu={dataMenu}
        itensFilter={subGroupItens}
        handleFilterProducts={handleFilterProducts}
      />
      <SideMenu 
        openMenu={drawerOpened}
        toggleOpenMenu={toggleDrawer}
        userName={username}
        dataMenu={dataMenu}
        subgroupItens={subGroupItens}
      />
    </Box>
    <div id='app-root' style={{
      marginTop: isPc? '8.3rem': '12rem'
    }}>
      {props.children}
    </div>
    <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp/>
        </Fab>
      </ScrollTop>
    <Footer />
  </>

}