import { useMediaQuery, useTheme } from "@mui/material";
import { createContext, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface ProductContextInterface {
  filter: string;
  type?: string|number;
  filterId?: string|number|undefined;
  handleFilterByName: (value: string) => void;
  handleFilterByType: (value: string|number) => void;
  handleFilterById: (value: string|number|undefined) => void;
  clearFilters: () => void
}

export const ProductsFilter = createContext<ProductContextInterface>({
  filter: "",
  type: "",
  filterId: "",
  handleFilterByName: (value: string) => {},
  handleFilterByType: (value: string|number) => {},
  handleFilterById: (value: string|number|undefined) => {},
  clearFilters: () => {}
});

export const ProductsFilterProvider = ({ children }:any) => { 
  const [ filter, setFilter ] = useState<string>("%")
  const [ type, setType ] = useState<string|number>("0")
  const [ filterId, setId ] = useState<string|number|undefined>("0")

  const theme = useTheme()

  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  const isMd = useMediaQuery(theme.breakpoints.up('md'))
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))
  const isXs = useMediaQuery(theme.breakpoints.up('xs'))

  const navigate = useNavigate()
  let timer:any = null

  const handleFilterByName = useCallback((value:string) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      let newFilter:string[] = []
      if(value) {
        var filtroAux = value.split(' ');
        filtroAux.map(i => newFilter.push(`%${i}%`));
      } else {
        newFilter.push('%');
      }
      navigate('/main')
      scrollToProducts()
      setId("0")
      setFilter(newFilter.join(''))
      return newFilter.join('')
    }, 1000)
  },[])

  const handleFilterByType = useCallback((value:string|number) => {
    navigate('/main')
    scrollToProducts()
    setType(value)
  },[])

  const handleFilterById = useCallback((value:string|number|undefined) => {
    navigate('/main')
    scrollToProducts()
    setFilter("")
    setId(value)
  },[])

  const clearFilters = () => {
    setFilter("%")
    setId("0")
    setType("0")
  }

  const scrollToProducts = () => {
    if(isXl){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    else if(isLg){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    else if(isMd){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    else if(isSm){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    else if(isXs){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  const value = useMemo(() =>({
    filter,
    type,
    filterId,
    handleFilterByName,
    handleFilterByType,
    handleFilterById,
    clearFilters
  }),[filter, type, filterId, handleFilterByName, handleFilterByType, handleFilterById, clearFilters])

  return(
    <ProductsFilter.Provider value={ value }>
      {children}
    </ProductsFilter.Provider>
  )
 }