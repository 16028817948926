import { AlertColor } from "@mui/material"
import { useCallback, createContext, useState } from "react"


export type Alert = {
  open: boolean,
  message: string,
  severity: AlertColor
}

export interface FeedbackContextInterface {
  alert: Alert,
  loader: boolean,
  showAlert: (message:string, severity:AlertColor) => void
  hideAlert: () => void
  setLoader: (newState:boolean) => void
}

export const FeedbackContext = createContext<FeedbackContextInterface>({
  alert: {open: false, message: '', severity: 'info'},
  loader: false,
  showAlert: (message:string, severity:string) => {},
  hideAlert: () => {},
  setLoader: (newState:boolean) => {},
})

export default function FeedbackProvider(props:any){
  const [alert, setAlert] = useState<Alert>({open: false, severity: "info", message: ""})
  const [loaderOpen, setLoaderOpen] = useState<boolean>(false)

  const hideAlert = useCallback(() => {
    setAlert((prevState:any) => ({...prevState, open:false}))
  },[])

  const showAlert = useCallback((message: string, severity: AlertColor) => {
    setAlert({ open:true, severity, message })
    setTimeout(()=>{
      hideAlert()
    }, 5000)
  },[hideAlert])

  const setLoader = useCallback((newState:boolean)=> {
    setLoaderOpen(newState)
  },[])

  const contextValue = {
    alert,
    loader: loaderOpen,
    showAlert: useCallback((message:string, severity:AlertColor)=> showAlert(message, severity), [showAlert])                                       ,
    hideAlert: useCallback(()=> hideAlert(), [hideAlert]),
    setLoader: useCallback((newState:boolean)=> setLoader(newState), [setLoader])
  }

  return(
    <FeedbackContext.Provider value={contextValue}>
      {props.children}
    </FeedbackContext.Provider>
  )
}
