import Convey from "../../infra/http/convey"
import { clearAllCookies, getTokenPayload } from "../../utility/functions/storage"


export default class Model {
  public static async getSubgroupItens(){
    const { data, status } = await Convey.post('/lojavendasinternas/subgrupos')

    if(status !== 200) return { data: [], status: 400 }
    return { data, status }
  }

  public static async verifyToken(token:string){
    try {
      const { status } = await Convey.get('/validate-token', { Authorization: "Bearer " + token })

      if(status === 200) return { authorized: true, status: 200 }
      else return { authorized: false, status: 401 }

    } catch(error:any){
      console.log(error)
      return { authorized: false, status: 400 }
    }
  }

  public static handleUnauthorized(){
    try {
      clearAllCookies()
      this.redirect()

    } catch(error:any){
      console.log(error)
      this.redirect()
    }
  }
  private static redirect(){  
    window.location.href="/"
  }

  public static async getShoppingCart(){
    const userData = parseInt(getTokenPayload().nameid)
    const { data, status } = await Convey.post(`/lojavendasinternas/carrinhocompleto/${userData}`)
    if(status !== 200){
      return { data: [], status: 400 }
    } 
    return { data }
  }
}