import { getCookie } from "../../utility/functions/storage"

export interface ConveyResponse {
  data?: any
  config: any
  headers: any
  status: number
  statusText?: string
}

enum ApiURL {
  https = "https://apis.cifaldistribuidora.com.br:8000"
}

interface DispatchParams { 
  url: string
  headers?: Headers
  data?: any
  https: boolean
  method:  "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS"
}

const defaultRequestConfig = {
  mode: "cors",
  method: "GET",
  keepalive: false,
  window: null,
  headers: {
    "Accept": "application/json, text/plain, */*",
    "Content-Type": "application/json"
  }
}

export default class Convey {
  public static async get(url:string, headers?:any, https:boolean=true){
    return await this.dispatch({
      method: "GET",
      url, 
      headers, 
      https
    })
  }

  public static async post(url:string, data?:any, headers?:any, https:boolean=true){
    return await this.dispatch({
      method: "POST", 
      url, 
      headers, 
      data, 
      https
    })
  }

  public static async put(url:string, data?:any, headers?:any, https:boolean=true){
    return await this.dispatch({
      method: "PUT", 
      url, 
      headers, 
      data, 
      https
    })
  }

  public static async delete(url:string, headers?:any, https:boolean=true){
    return await this.dispatch({
      method: "DELETE",
      url, 
      headers, 
      https
    })
  }

  public static async options(url:string, headers?:any, https:boolean=true){
    return await this.dispatch({
      method: "OPTIONS",
      url, 
      headers, 
      https
    })
  }

  private static async dispatch(params:DispatchParams){
    const fetchConfig = {
      ...defaultRequestConfig,
      method: params.method,
      headers: {
        ...defaultRequestConfig.headers,
        Authorization: `Bearer ${getCookie('token')}`,
        ...params.headers,
      },
      body: JSON.stringify(params.data)
    } as RequestInit

    const response = await fetch(
      (ApiURL.https) + params.url,
      fetchConfig
    )
    var json = await response.json()

    return {
      data: json,
      config: {
        ...defaultRequestConfig,
        method: params.method
      },
      headers: fetchConfig.headers,
      status: response.status,
      statusText: response.statusText
    } as ConveyResponse
  }

  public static async authenticate (username:string, password:string, https:boolean=true) {
    const fetchConfig = {
      method: "POST",
      headers: { ...defaultRequestConfig.headers },
      body: JSON.stringify({username, password})
    } as RequestInit
    
    const response = await fetch(`${ApiURL.https}/auth`, fetchConfig)
  
    return {
      data: await response.json(),
      config: {
        ...defaultRequestConfig,
        method: "POST"
      },
      headers: fetchConfig.headers,
      status: response.status,
      statusText: response.statusText
    } as ConveyResponse
  }
}