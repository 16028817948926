import { useState } from "react";


export default function useLocalStorage(key:string, initialValue:string) {
  const [storedValue, setStoredvalue] = useState(() => {
    if (typeof window === "undefined") return initialValue;
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch(error){
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value:any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredvalue(valueToStore);

      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch(error) {
      console.log(error)
    }
  };
  return [storedValue, setValue];
  
}