import { useState } from "react";
import { getCookie, setCookie } from "../functions/storage";

export default function useCookie(key:string, initialValue:string):[string, (value:any, days:number) => void]{
  const [ storedValue, setStoredvalue ] = useState<string>(() => {
    if(typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = getCookie(key);
      return item? item : initialValue;
    } catch(error) {
      console.log(error)
      return initialValue;
    }
  });

  const setValue = (value:any, days:number) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredvalue(valueToStore);

      if (typeof window !== "undefined") setCookie(key, valueToStore, days);

    } catch(error) {
      console.log(error);
    }
  };
  return [ storedValue, setValue ];
}